<script setup lang="ts">
import { inject, ref } from 'vue'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination } from 'swiper/modules'

// Components
import EndlessSlide from './SlicesEndlessSlide.vue'
import { defineSlice } from '#imports'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

defineSlice({
  name: {
    label: 'Endless Slider',
    group: 'Slices',
  },
  description: 'An endless slider',
})

const layout = inject('voixLayout')

const slides = [
  {
    location: 'Italy',
    name: 'Amalfi Coast',
    image:
        'https://images.unsplash.com/photo-1516483638261-f4dbaf036963?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80',
  },
  {
    location: 'Ireland',
    name: 'Belfast',
    image:
        'https://images.unsplash.com/photo-1505576457712-b769c0c0a354?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80',
  },
  {
    location: 'New York, USA',
    name: 'Catskil Mountains',
    image:
        'https://images.unsplash.com/photo-1637263706784-4836355ab1c8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1064&q=80',
  },
  {
    location: 'France',
    name: 'Burgandy',
    image:
        'https://images.unsplash.com/photo-1572970107561-74841d031857?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1034&q=80',
  },
  {
    location: 'Egypt',
    name: 'Cairo',
    image:
        'https://images.unsplash.com/photo-1572252009286-268acec5ca0a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80',
  },
  {
    location: 'Switzerland',
    name: 'Zermatt',
    image:
        'https://images.unsplash.com/photo-1586752488885-6ce47fdfd874?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=772&q=80',
  },
]
</script>

<template>
  <div class="py-24 relative z-10 select-none">
    <div
      v-if="layout === 'RedesignedLayout'"
      class="container mx-auto mb-12 flex flex-col items-center text-center"
    >
      <div class="text-xs sm:text-sm uppercase font-bold">
        Where in the world
      </div>
      <div
        class="text-2xl sm:text-3xl uppercase font-extrabold text-brand-orange underline underline-offset-4 sm:underline-offset-8 leading-snug"
      >
        Are we off to next?
      </div>
    </div>

    <ClientOnly>
      <Swiper
        :centered-slides="false"
        :space-between="30"
        :pagination="{
          clickable: true,
        }"
        :loop="false"
        :modules="[Pagination]"
        :slides-per-view="6"
        :breakpoints="{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          720: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          900: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 40,
          },
        }"
      >
        <SwiperSlide
          v-for="(slide, slideIndex) in slides"
          :key="slideIndex"
          class="pb-10"
        >
          <EndlessSlide :fields="slide" />
        </SwiperSlide>
      </Swiper>
    </ClientOnly>
  </div>
</template>
